<template>
  <v-list>
    <v-list-item>
      <v-list-item-icon>
        <v-icon>mdi-currency-eur</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ plan.name }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-if="plan.comments">
      <v-list-item-icon>
        <v-icon>mdi-page-layout-footer</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="caption">{{
          plan.comments
        }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-if="plan.contractable">
      <v-list-item-icon>
        <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>Contractable</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-icon>
        <v-icon>mdi-cash</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ plan.price_cents / 100 }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-icon>
        <v-icon>mdi-harddisk</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title
          >{{ plan.disk_signature_packages_mb }} Mb</v-list-item-title
        >
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-icon>
        <v-icon>mdi-pen</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ plan.signatures }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-icon>
        <v-icon>mdi-pen-plus</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ plan.signup_signatures }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: "PricingPlanCard",
  props: { plan: { type: Object, required: true } }
};
</script>

<style scoped></style>

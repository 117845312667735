<template>
  <v-expansion-panels flat focusable hover v-if="plans.length > 0">
    <v-expansion-panel v-for="plan in plans" :key="plan.id">
      <v-expansion-panel-header>
        <v-row wrap>
          <v-col cols="1">
            <div>
              <v-icon small v-if="plan.contractable"
                >mdi-checkbox-marked-circle-outline</v-icon
              >
              <v-icon small v-else>mdi-checkbox-blank-circle-outline</v-icon>
            </div>
          </v-col>
          <v-col cols="10">
            {{ plan.name }}
          </v-col>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content
        ><PricingPlanCard :plan="plan" />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import PricingPlanCard from "@/components/PricingPlanCard.vue";

export default {
  components: { PricingPlanCard },
  props: {
    plans: { type: Array, required: true }
  }
};
</script>

<style scoped>
.v-list-item {
  border-left: 4px white solid;
}

.v-list-item.disabled {
  border-left-color: #e57373;
}
</style>

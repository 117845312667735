<template>
  <div>
    <h3 class="text-h3">Pricing Plans</h3>
    <div class="mb-4 caption">
      <div v-if="loading">Loading...</div>
      <div v-else>
        <div>{{ plans.length }} results found</div>
        <PricingPlanList :plans="plans" />
      </div>
    </div>
  </div>
</template>

<script>
import PricingPlanList from "@/components/PricingPlanList.vue";
import Backend from "@/services/Backend";

export default {
  components: {
    PricingPlanList
  },
  data() {
    return {
      prefix: "",
      plans: [],
      loading: false
    };
  },
  mounted() {
    return this.fetch();
  },
  methods: {
    async fetch() {
      this.loading = true;
      this.plans = [];
      let response = await Backend.getPricingPlans(this.prefix);
      this.plans = response.data.sort((x, y) =>
        x.name.toLowerCase() < y.name.toLowerCase() ? -1 : 1
      );
      this.loading = false;
    }
  }
};
</script>
